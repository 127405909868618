<template>
  <div>
    <div
      v-if="!requisitoModal"
      class="d-flex mb-8"
    >
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.nome"
        class="col-12 col-md-6"
        :label="$t('modulos.requisitos.formulario.nome')"
        obrigatorio
        :max="200"
        trim
      />
      <input-select
        v-model="form.tiposRequisito"
        class="col-12 col-md-6"
        :label="
          $t('modulos.requisitos.formulario.tipo_requisito')"
        :options="opcoes.tiposRequisito"
        obrigatorio
        multiplos
      />
      <input-text
        v-model="form.requisitoSim"
        class="col-12 col-md-6"
        :label="$t('modulos.requisitos.formulario.requisito_sim')"
        obrigatorio
        :max="200"
        trim
      />
      <input-text
        v-model="form.requisitoNao"
        class="col-12 col-md-6"
        :label="$t('modulos.requisitos.formulario.requisito_nao')"
        :max="200"
        trim
      />
      <input-text
        v-model="form.textoRequisitoObservacao"
        class="col-12 col-md-12"
        :label="$t('modulos.requisitos.formulario.texto_entre_requisito_observacao')"
        :max="200"
        trim
      />
    </v-form>

    <v-divider class="my-5" />
    <div
      v-if="!requisitoModal"
      class="d-flex align-center justify-end"
    >
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import RequisitosService from '@common/services/cadastros/RequisitosService';
import { RequisitosModel } from '@common/models/cadastros/RequisitosModel';
import helpers from '@common/utils/helpers';
export default {
    props: {
    id: { type:String, default:'' },
    requisitoModal: { type:Boolean, default:false }
  },
  data() {
    return {
      valido: false,
      form: new RequisitosModel({}),
      opcoes: {
        tiposRequisito: helpers.TipoRequisitoEnum,
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.requisitos.titulos.editar');
      return this.$t('modulos.requisitos.titulos.novo');
    },
  },
  watch: {
    form: {
      handler() {
        this.$emit('form-requisitos', this.form)
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.id) {
      this.buscar(this.id)
      helpers.redirecionarSemPermissao(this, 'Marca', 'Editar')
    }
    helpers.redirecionarSemPermissao(this, 'Marca', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RequisitosService.buscar(this.id)
        .then((res) => {
          this.form = new RequisitosModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.requisitos.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if(!this.valido) return
      this.$store.dispatch('Layout/iniciarCarregamento');
      RequisitosService.salvar(this.form)
        .then(() => {
          this.$router.push({ name: 'requisitos' })
          this.toastSucesso(this.$t(`modulos.requisitos.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.valido = false
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'requisitos' });
      });
    }
  },
};
</script>
